function pWelcome() {
  current(pages[0])
  dotArray[0].classList.add('is-active');
  previous.classList.add('is-disabled')
  
  next.addEventListener('click', nextButton, false)
  function nextButton(e) {
    e.target.removeEventListener('click', nextButton, false)
    nextPage(pages[1],pages[0])
    dotNavControl()
    pOne();
  }
}
function pOne() {
  previous.classList.remove('is-disabled')
  
  previous.addEventListener('click', previousButton, false)
  next.addEventListener('click', nextButton, false)

  function previousButton(e) {
    e.target.removeEventListener('click', previousButton, false)
    next.removeEventListener('click', nextButton, false)
    e.stopPropagation()
    previousPage(pages[0],pages[1])
    dotNavControl()
    pWelcome()
  }
  
  function nextButton(e) {
    e.target.removeEventListener('click', nextButton, false)
    previous.removeEventListener('click', previousButton, false)
    nextPage(pages[2], pages[1])
    dotNavControl()
    pTwo()
  }

}
function pTwo() {
  previous.addEventListener('click', previousButton, false)
  next.addEventListener('click', nextButton, false)

  function previousButton(e) {
    e.target.removeEventListener('click', previousButton, false)
    next.removeEventListener('click', nextButton, false)
    e.stopPropagation()
    previousPage(pages[1],pages[2])
    dotNavControl()
    pOne();
  }  
  
  function nextButton(e) {
    e.target.removeEventListener('click', nextButton, false)
    previous.removeEventListener('click', previousButton, false)
    nextPage(pages[3],pages[2])
    dotNavControl()
    pThree();
  }
}

function pThree(){
  previous.addEventListener('click', previousButton, false)
  next.addEventListener('click', nextButton, false)

  function previousButton(e) {
    e.target.removeEventListener('click', previousButton, false)
    next.removeEventListener('click', nextButton, false)
    e.stopPropagation()
    previousPage(pages[2],pages[3])
    dotNavControl()
    pTwo();
  }

  function nextButton(e) {
    e.target.removeEventListener('click', nextButton, false)
    previous.removeEventListener('click', previousButton, false)
    nextPage(pages[4],pages[3])
    dotNavControl()
    pFour();
  }
}
function pFour(){

  previous.addEventListener('click', previousButton, false)
  next.addEventListener('click', nextButton, false)

  function previousButton(e) {
    e.target.removeEventListener('click', previousButton, false)
    next.removeEventListener('click', nextButton, false)
    e.stopPropagation()
    previousPage(pages[3],pages[4])
    dotNavControl()
    pThree();
  }

  function nextButton(e) {
    e.target.removeEventListener('click', nextButton, false)
    // previous.removeEventListener('click', previousButton, false)
  }
}
