"use strict";
let pages = []
let dotArray = []
let currentPageNumber = 0
window.addEventListener("load", () => {
  let previous = document.querySelector('#previous'),
      next = document.querySelector('#next')
  loadPages()
  createNav()

  pWelcome()
  
});