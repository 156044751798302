// creates the dot navigation based on the amount of pages added in
let createNav = () => {
  let nav = document.querySelector('.nav--dots')
  let i
  for (i = 0; pages.length > i; i++) {
    nav.innerHTML += "<span> </span>"
    nav.children[i].classList.add("dots--dot")
    nav.children[i].id = 'dot-' + [i]
  }
  let dots = document.querySelectorAll('.dots--dot')
  for (let dot of dots) {
    dotArray.push(dot)
  }

}