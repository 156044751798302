// Function that allows page to be statically placed in the 0,0 position
function current(currentPage) {
  currentPage.style.top=0
  currentPage.style.left=0
}
// Next Page animation
function nextPage(nextPage, currentPage) {
  currentPageNumber += 1
  let sounds = new playSounds()
  sounds.playRandomSound()
  try {
    nextPage.animate([
      {
        top: '200%',
        left: '200%'
      },
      {
        top: '0',
        left: '0'
      }], {
        duration: 1000,
        easing: "linear",
        direction: "normal",
        fill: "forwards"
      }) 
  } catch (error) {
    console.error(error)
  }

  try {
    currentPage.animate([
      {
        top: '0',
        left: '0'
      },
      {
        top: '200%',
        left: '-200%'
      }], {
      duration: 1000,
      easing: "linear",
      direction: "normal",
      fill: "forwards"
    }, false)
  } catch (error) {
    console.error(error)
  }
}

function previousPage(previousPage, currentPage) {
  currentPageNumber -= 1
  let sounds = new playSounds()
  sounds.playRandomSound()
  try {
    previousPage.animate([
      {
        top: '200%',
        left: '-200%'
      },
      {
        top: '0',
        left: '0'
      }], {
        duration: 1000,
        easing: "linear",
        direction: "normal",
        fill: "forwards"
      }) 
  } catch (error) {
    console.error(error)
  }

  try {
    currentPage.animate([
      {
        top: '0',
        left: '0'
      },
      {
        top: '200%',
        left: '200%'
      }], {
      duration: 1000,
      easing: "linear",
      direction: "normal",
      fill: "forwards"
    }, false)
  } catch (error) {
    console.error(error)
  }
}

function dotNavControl () {
  try {
    if (currentPageNumber => 0 && currentPageNumber < dotArray.length) {
      for (let dot of dotArray) {
        if (dot.classList.contains('is-active')) {
          dot.classList.remove('is-active')
          console.log(dot)
        }
        if (dot.id == 'dot-' + currentPageNumber)
          dot.classList.add('is-active')
      }
    } 
      dotArray[currentPageNumber].classList.add('is-active');
  } catch (error) {
    console.log(error)
  }
}