class playSounds {
  constructor(){
  }
  randomNumber() {
    return (Math.floor(Math.random() * 10))
  }
  playRandomSound() {
    let soundSrc = [
      {
          0: {
            "start": 0,
            "end": 2.16546485260771,
            "loop": false
          },
          1: {
            "start": 4,
            "end": 6.16546485260771,
            "loop": false
          },
          2: {
            "start": 8,
            "end": 10.165464852607709,
            "loop": false
          },
          3: {
            "start": 12,
            "end": 14.165464852607709,
            "loop": false
          },
          4: {
            "start": 16,
            "end": 18.16546485260771,
            "loop": false
          },
          5: {
            "start": 20,
            "end": 22.16546485260771,
            "loop": false
          },
          6: {
            "start": 24,
            "end": 26.16546485260771,
            "loop": false
          },
          7: {
            "start": 28,
            "end": 30.16546485260771,
            "loop": false
          },
          8: {
            "start": 32,
            "end": 34.16546485260771,
            "loop": false
          },
          9: {
            "start": 36,
            "end": 38.16546485260771,
            "loop": false
          },
          10: {
            "start": 40,
            "end": 42.16546485260771,
            "loop": false
          },
          11: {
            "start": 44,
            "end": 46.16546485260771,
            "loop": false
          }
      }
    ]


    let random = this.randomNumber(),
        start = soundSrc[0][random].start,
        end = soundSrc[0][random].end

    let sound = document.querySelector('#sound')
      
    sound.currentTime = start;
    sound.play()

    setInterval(function(){
      if(sound.currentTime>end){
        sound.pause();
      }
    }, 1000);
  }
}