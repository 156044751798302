var loadPages = function loadPages() {
  var welcome = document.querySelector('#welcome'),
      page1 = document.querySelector('#page-1'),
      page2 = document.querySelector('#page-2'),
      page3 = document.querySelector('#page-3'),
      page4 = document.querySelector('#page-4'),
      page5 = document.querySelector('#page-5'),
      page6 = document.querySelector('#page-6'),
      page7 = document.querySelector('#page-7'),
      resources = document.querySelector('#resources');
  pages.push(welcome);
  pages.push(page1);
  pages.push(page2);
  pages.push(page3);
  pages.push(page4);
  pages.push(resources);
}; // creates the dot navigation based on the amount of pages added in
